import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ChartJS from 'chart.js/auto';

import { chartData as defaultChartData, chartOptions as defaultChartOptions } from '../../config/chartConfig';
import { Line, Bar } from 'react-chartjs-2';
import './Chart.css';

function Chart({ index, language }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [fullData, setFullData] = useState({});
  const [chartType, setChartType] = useState('');
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const location = useLocation()
  var data;
  var indicatorFile;

  useEffect(() => {
    const fetchIndicatorFile = async () => {
      // Fetch individual indicator
      const path = location.pathname;
      indicatorFile = await import(`../../data${path}.json`);
      data = (indicatorFile.default);

      setIsLoading(false);

      setChartType(data.type);

      // Load default style
      setChartData(defaultChartData(data));
      setChartOptions(defaultChartOptions(data));

      setFullData(data);

      // Filter Data with default period
      const filteredData = filterData(data.dataset, data.default);

      // Filter labels from the selected time period
      const filteredLabels = filteredData.map(reg => {
        let month = language.months[parseInt(reg.date.split('/')[1]) - 1];
        let year = reg.date.split('/')[0];

        if (month !== undefined) {
          return `${month} ${year}`;
        } else {
          return year;
        }
      });

      setChartData(prevChartData => {
        return {
          ...prevChartData,
          name: data.id,
          updated: data.updated,
          years: data.default,
          labels: filteredLabels,
          source: data.source,
          sourceUrl: data.sourceUrl,
          range: data.range,
          datasets: [
            {
              ...prevChartData.datasets[0], // Para mantener los estilos de linea
              data: filteredData.map(reg => reg.value)
            }
          ]
        }
      });
    }

    fetchIndicatorFile();

  }, [location.pathname]);

  const changePeriod = (data, years) => {
    // Filter data
    const filteredData = filterData(data.dataset, years);

    const filteredLabels = filteredData.map(reg => {
      let month = language.months[parseInt(reg.date.split('/')[1]) - 1];
      let year = reg.date.split('/')[0];

      if (month !== undefined) {
        return `${month} ${year}`;
      } else {
        return year;
      }
    });

    setChartData(prevChartData => ({
      ...prevChartData,
      years: years,
      labels: filteredLabels,
      datasets: [
        {
          ...prevChartData.datasets[0], // Para mantener los estilos de linea
          data: filteredData.map(reg => reg.value)
        }
      ]
    }));
  }

  const changeType = (event) => {
    setChartType(event.target.value);
    setIsChecked(!isChecked);
  }

  const togglePoints = () => {
    setChartOptions({
      elements: {
        point: {
          radius: chartOptions.elements.point.radius === 4 ? 0 : 4
        }
      }
    });
  }

  const filterData = (data, years) => {
    if (years === 'MAX') {
      return data; // Returns unfiltered full data
    } else {
      const endYear = new Date(data[data.length - 1].date).getFullYear(); // Last available data

      const startYear = endYear - parseInt(years);

      const filteredData = data.filter(item => {
        const itemYear = new Date(item.date).getFullYear();

        return itemYear > startYear && itemYear <= endYear; // Incluye el último año
      })

      return filteredData;
    }
  }

  if (!isLoading) {
    console.log(chartData.years);
    return (
      <div className='chart-area'>
        <div className='chart-header'>
          <div className='right'>
            <div className='timespan'>
              {
                chartData.range.map((element, index) => (
                  <a
                    key={index}
                    href="#"
                    name={element}
                    onClick={(e) => changePeriod(fullData, e.target.name)}
                    className={chartData.years == element ? 'selected' : ''}
                  >
                    {element === 'MAX' ? element : `${element}${language.y}`}
                  </a>
                ))
              }
            </div>

            <select name="" id="" className='chart-type' onChange={changeType}>
              <option value="line">{language.line}</option>
              <option value="bar">{language.bar}</option>
            </select>
          </div>
        </div>

        <div className='container'>
          <div className='chart-container'>
            {chartType === 'line' && <Line data={chartData} options={chartOptions} className='chart' />}
            {chartType === 'bar' && <Bar data={chartData} options={chartOptions} className='chart' />}
          </div>

          <a className='source' href={chartData.sourceUrl} target='_blank'>{language.source}: {chartData.source}</a>
        </div>
      </div>
    )
  }
}

export default Chart;