import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Chart from '../components/Chart/Chart';
import MoreIndicators from '../components/MoreIndicators/MoreIndicators';

function Details({ index, language }) {
  const { indicator } = useParams();

  return (
    <div className='details-page'>
      <div className='indicator-area'>
        <div className='indicator-header'>
          <h1>{language[indicator]}</h1>


          <p className='indicator-description'>{language[indicator + '.description']}</p>

          <div className='updated'>
            <img src='../img/icons/clock.svg' className='updated' />
            {/* <span>{`${language.updated} ${chartData.updated}`}</span> */}
          </div>
        </div>
        
        <Chart index={index} language={language} />
      </div>

      <MoreIndicators index={index} language={language} />
    </div>
  )
}

export default Details;