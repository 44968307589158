import React, { useState, useEffect } from 'react';
import Card from '../components/Card/Card';

function Overview({ index, handleClick, language }) {
  const [isLoading, setIsLoading] = useState(true);
  const [indicators, setIndicators] = useState({});

  useEffect(() => {
    setIndicators(index);
    setIsLoading(false);
  })

  const countIndicators = () => {
    let count = 0;

    // indicators.categories.map((category) => {
    //   category.indicators.map((indicator) => {
    //     count++;
    //   })
    // })

    return count;
  }

  if (!isLoading) {
    return (
      <div className='indicators-page'>
        <h1>{language.overview}</h1>
        <span className='counter'>{countIndicators()} {language.indicators}</span>

        {/* <div className='indicators-grid'>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            indicators.categories.map((category) => (
              category.indicators.map((indicator) => (
                <Card indicator={indicator} language={language} />
              ))
            ))
          )}
        </div> */}
      </div>
    )
  }
}

export default Overview;