import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './Menu.css';

function Menu({ index, language, isMenuVisible, toggleMenuVisibility }) {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState({});
  const location = useLocation();

  useEffect(() => {
    setCategories(index.categories);
    setIsLoading(false);
  }, [categories]);

  const isSelected = (categoryPath) => {
    return location.pathname === categoryPath;
  };

  if (!isLoading) {
    return (
      <nav className={`nav-bar ${isMenuVisible ? '' : ' collapsed'}`}>
        <div className='logo-container'>
          <Logo />
          <img src="../img/icons/hide.svg" className='close' onClick={toggleMenuVisibility} />
        </div>

        <ul className='menu'>
          {index.namespaces.map((namespace) => (
            <div key={namespace.name}>
              <p className='namespace'>{namespace.name}</p>
              
              <ul>
                {namespace.categories.map((category) => (
                  <li key={category.id} className={isSelected(category.path) ? 'selected' : ''}>
                    <Link to={category.path}>
                      <img src={category.icon} alt={category.name} className='icon' />
                      <span>{language[category.name]}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </ul>
      </nav>
    )
  }

  return null;
}

export default Menu;