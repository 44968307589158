import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../Header/Header';
import Overview from '../../pages/Overview';
import Category from '../../pages/Category';
import Details from '../../pages/Details';
import './Main.css';

function Main({ index, language, handleLanguage, toggleMenuVisibility }) {
    return (
        <div className='main'>
            <Header index={index} language={language} handleLanguage={handleLanguage} toggleMenuVisibility={toggleMenuVisibility}/>

            <Routes>
                <Route exact path='/' element={<Overview index={index} language={language} />} />

                <Route path='/gdp' element={<Category index={index} language={language} />} />
                <Route path='/gdp/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/employment' element={<Category index={index} language={language} />} />
                <Route path='/employment/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/prices' element={<Category index={index} language={language} />} />
                <Route path='/prices/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/people' element={<Category index={index} language={language} />} />
                <Route path='/people/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/government' element={<Category index={index} language={language} />} />
                <Route path='/government/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/trade' element={<Category index={index} language={language} />} />
                <Route path='/trade/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/money' element={<Category index={index} language={language} />} />
                <Route path='/money/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/tourism' element={<Category index={index} language={language} />} />
                <Route path='/tourism/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/crime' element={<Category index={index} language={language} />} />
                <Route path='/crime/:indicator' element={<Details index={index} language={language} />} />

                <Route path='/education' element={<Category index={index} language={language} />} />
                <Route path='/education/:indicator' element={<Details index={index} language={language} />} />
            </Routes>
        </div>
    )
}

export default Main;