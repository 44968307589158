import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Line, Bar } from 'react-chartjs-2';
import './Card.css';

function Card({ indicator, language }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    formatValue(indicator);
    setIsLoading(false);
  }, [])

  const filterLabels = () => {
    let labels = [];

    indicator.snapshot.map(reg => {
      let parts = reg.date.split('/').length;
      let day = '';
      let month = '';
      let year = '';
      let fullDate;
      
      switch (parts) {
        case 1:
          // YYYY (length 1)
          year = reg.date.split('/')[0];
          fullDate = `${year}`;
          labels.push(fullDate);
          break;

        case 2:
          // YYYY/MM (length 2)
          year = reg.date.split('/')[0];
          month = language.months[parseInt(reg.date.split('/')[1]) - 1];
          fullDate = `${month} ${year}`;
          labels.push(fullDate);
          break;

        case 3:
          // YYYY/MM/DD (length 3)
          year = reg.date.split('/')[0];
          month = language.months[parseInt(reg.date.split('/')[1]) - 1];
          day = reg.date.split('/')[2];
          fullDate = `${day} ${month} ${year}`;
          labels.push(fullDate);
          break;
      }      
    });

    return labels;
  }

  const formatValue = (indicator) => {
    indicator.snapshot.map((snapshot) => {
      console.log(snapshot.value);
    });
  }

  const chartData = {
    labels: filterLabels(),
    datasets: [{
      backgroundColor: (context) => {
        const value = context.dataset.data[context.dataIndex];
        if (indicator.chartType === 'line') {
          return '#fcd116ff'
        } else {
          return value < 0 ? 'red' : 'green'; // Cambia el color a rojo si el valor es negativo
        }
        
      },

      borderColor: (context) => {
        const value = context.dataset.data[context.dataIndex];
        if (indicator.chartType === 'line') {
          return '#141eaa'
        } else {
          return 'transparent'; // Igual que el color de fondo
        }

      },
      borderWidth: (context) => {
        return indicator.chartType === 'line' ? 3 : 0; // Aplicar borderWidth solo para líneas
      },
      data: indicator.snapshot.map((snapshot) => snapshot.value),
    }]
  }

  const chartOptions = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      }

    },
    animation: {
      duration: 1
    },
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 3
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(50, 50, 200, 0.7)',
        padding: 10,
        displayColors: false,
        intersect: false,
        position: 'nearest',
        mode: 'index'
      }
    },
    chartArea: {
      backgroundColor: 'rgba(0, 0, 0, 1)'
    }
  }

  if (!isLoading) {
    return (
      <Link className='card' to={indicator.path}>
        <h2 className='card-name'>{language[indicator.id]}</h2>
        <div className='card-content'>
          <div className='left'>
            {
              indicator.type === '%' || indicator.type === 'pts' ?
                <>
                  <span className={`quantity ${indicator.snapshot[indicator.snapshot.length - 1].value < 0 ? 'negative' : ''}`}>{indicator.snapshot[indicator.snapshot.length - 1].value.toLocaleString('es-ES')}</span>
                  <span className={`type ${indicator.snapshot[indicator.snapshot.length - 1].value < 0 ? 'negative' : ''}`}>{indicator.type}</span>
                </> : ''
            }

            {
              indicator.type === 'U$S' || indicator.type === '$' ?
                <>
                  <span className='type'>{indicator.type}</span>
                  <span className='quantity'>{indicator.snapshot[indicator.snapshot.length - 1].value.toLocaleString('es-ES')}</span>
                  <p className='unit'>{language[indicator.unit]}</p>
                </> : ''
            }

            {
              indicator.type === '' ?
                <>
                  <span className='quantity'>{indicator.snapshot[indicator.snapshot.length - 1].value.toLocaleString('es-ES')}</span>
                  <p className='unit'>{language[indicator.unit]}</p>
                </> : ''
            }
          </div>

          <div className='right'>
            <div className='snapshot-container'>
              {indicator.chartType === 'line' ?
                <>
                  <Line data={chartData} options={chartOptions} className='snapshot' />
                </> :
                <>
                  <Bar data={chartData} options={chartOptions} className='snapshot' />
                </>
              }
            </div>
          </div>
        </div>

      </Link>
    )
  }
}

export default Card;