import React from "react";
import './Info.css';
import Logo from '../Logo/Logo';

function Info({ language, handleClose }) {
  return (
    <div className='info' id='info' onClick={(e) => e.target.id === 'info' ? handleClose() : null}>
      <div className="container">
        <Logo />

        <p>{language.info}</p>

        <p>Developed by  <a href='http://www.jpmsoftware.com' target="_blank"><mark>JPM</mark></a></p>

        <img src="../img/icons/cross.svg" alt="close" className="icon-close" onClick={handleClose} />
      </div>
    </div>
  )
}

export default Info;