import React from 'react';
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <Link to='/' className='logo-container'>
      <img className='logo' src="../img/logo.svg" alt="logo" />
    </Link>
  )
}

export default Logo;