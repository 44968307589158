import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SearchSuggestions.css';

function SearchSuggestions({ searchString, index, language, clearSearch }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    clearSearch();
  };

  return (
    <div className={`suggestions ${isOpen ? 'open' : 'closed'}`}>
      {
        index.namespaces.map((namespace) => {
          return namespace.categories.map((category) => {
            return category.indicators.map((indicator) => {
              console.log(indicator.id);
              if (language[indicator.id].toLowerCase().includes(searchString)) {
                return (
                  <Link key={indicator.id} to={indicator.path} onClick={handleClose}>
                    {language[indicator.id]}
                  </Link>
                );
              }
              return null;
            });
          });
        })
      }
    </div>
  )
}

export default SearchSuggestions;