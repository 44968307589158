import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import './MoreIndicators.css';

function MoreIndicators({ index, language }) {
  // Given a category (path), load more indicators from the same path
  const location = useLocation();
  const currentCategory = location.pathname.split('/')[1];

  return (
    <div className='more-indicators'>
      <h3>{language.more}</h3>
      
      {index.namespaces.map((namespace) => (
        namespace.categories.map((category) => (
          category.name === currentCategory ? (
            category.indicators.map((indicator) => (
              indicator.path !== location.pathname ? (
                <Link to={indicator.path} className='card' key={indicator.id}>
                  <p>{language[indicator.id]}</p>
                </Link>
              ) : null
            ))
          ) : null
        ))
      ))}
    </div>
  )
}

export default MoreIndicators;