export const chartData = (data) => {
  return {
    datasets: [
      {
        borderWidth: (context) => {
          return data.type === 'line' ? 3 : 0;
        },
        backgroundColor: (context) => {
          const value = context.dataset.data[context.dataIndex];
          return value < 0 ? 'red' : 'green';
        },
        borderColor: data.type === 'line' ? '#0038a8' : 'none',
      }
    ]
  }
}

export const chartOptions = (data) => {
  return {
    animation: true,
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.2,
        cubicInterpolationMode: 'default'
      },
      point: {
        radius: 0,
        hoverRadius: 7,
        hoverBorderWidth: 3
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255)',
        titleColor: 'rgba(0, 0, 0, 1)', // Color de la fuente del título
        bodyColor: 'rgba(0, 0, 0, 1)', // Color de la fuente del cuerpo
        borderColor: 'rgba(0, 0, 0, 1)', // Color del borde del tooltip
        borderWidth: 1,
        intersect: false,
        position: 'nearest',
        mode: 'index',
        displayColors: false,
        padding: 20,
        titleFont: {
          family: 'Arial', // Fuente del título
          size: 14, // Tamaño de la fuente del título
          style: 'bold', // Estilo de la fuente del título
          weight: 'bold' // Peso de la fuente del título
        },
        bodyFont: {
          family: 'Arial', // Fuente del cuerpo
          size: 14, // Tamaño de la fuente del cuerpo
          style: 'normal', // Estilo de la fuente del cuerpo
          weight: 'normal' // Peso de la fuente del cuerpo
        },
      },
      annotation: {
        annotations: [
          {
            id: 'verticalLine',
            type: 'line',
            mode: 'vertical',
            scaleID: 'x',
            borderColor: 'red',
            borderWidth: 1,
            borderDash: [5, 5],
            x: 0 // La posición X inicial puede ser cualquiera
          }
        ]
      }
    },
    scales: {
      x: {
        grid: {
          display: true
        },
        ticks: {
          maxTicksLimit: 7, // Establece el límite máximo de marcas de división en el eje X
          maxRotation: 0, // Establece la rotación máxima de las etiquetas a 0 grados
          autoSkip: true // Habilita el salto automático de las etiquetas para evitar solapamientos
        }
      },
      y: {
        min: () => {
          return data.min === null ? null : data.min;
        },
        max: () => {
          return data.max === null ? null : data.max;
        },
        grid: {
          display: true,
          borderDash: [5, 5] // Configura el patrón de línea punteada [ancho del trazo, espacio entre trazos]
        }
        // Otras configuraciones de escalas Y...
      },
      // Configuración de las escalas
    }
  }
}
