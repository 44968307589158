import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Card from '../components/Card/Card';

function IndicatorsByCategory({ index, language }) {
  const [indicators, setIndicators] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    setIndicators(index);
    setIsLoading(false);
  })

  const countIndicators = () => {
    let count = 0;

    index.namespaces.map((namespace) => {
      namespace.categories.map((category) => {
        if (category.path === path) {
          category.indicators.map((indicator) => {
            count++;
          })
        }
      })
    })

    return count;
  }

  if (!isLoading) {
    return (
      <div className='indicators-page'>
        <h1>
          {
            index.namespaces.map((namespace) => (
              namespace.categories.map((category) => (
                category.path === path ? language[category.name] : ''
              ))
            ))
          }
        </h1>

        <span className='counter'>{countIndicators()} {language.indicators}</span>

        <div className='indicators-grid'>
          {
            index.namespaces.map((namespace) => (
              namespace.categories.map((category) => (
                category.path === path ? category.indicators.map((indicator) => (
                  <Card indicator={indicator} language={language} />
                )) : <></>
              ))
            ))
          }
        </div>
      </div>
    )
  }

}

export default IndicatorsByCategory;